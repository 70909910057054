import React, { useState, useEffect } from 'react';
import { Save, Lock, Eye, EyeOff } from 'lucide-react';

const Admin = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [dates, setDates] = useState([
    { date: '', lieu: '' }
  ]);
  const [contact, setContact] = useState({
    email: 'contact@lavoixdeleau.fr',
    facebook: 'https://www.facebook.com/eve.lagrange.1'
  });

  const ADMIN_PASSWORD = 'voixdeleau2024';

  useEffect(() => {
    // Vérifier si déjà authentifié
    const isAdmin = localStorage.getItem('voixdeleau-admin');
    if (isAdmin) setIsAuthenticated(true);

    // Charger les données existantes
    const savedDates = localStorage.getItem('voixdeleau-dates');
    const savedContact = localStorage.getItem('voixdeleau-contact');
    
    if (savedDates) {
      const parsedDates = JSON.parse(savedDates);
      setDates(parsedDates.length > 0 ? parsedDates : [{ date: '', lieu: '' }]);
    }

    if (savedContact) {
      const parsedContact = JSON.parse(savedContact);
      setContact({
        email: parsedContact.email || 'contact@lavoixdeleau.fr',
        facebook: parsedContact.facebook || 'https://www.facebook.com/eve.lagrange.1'
      });
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === ADMIN_PASSWORD) {
      setIsAuthenticated(true);
      localStorage.setItem('voixdeleau-admin', 'true');
    } else {
      alert('Mot de passe incorrect');
    }
  };

  const handleDateChange = (index, field, value) => {
    const newDates = [...dates];
    newDates[index][field] = value;
    setDates(newDates);
  };

  const addDate = () => {
    setDates([...dates, { date: '', lieu: '' }]);
  };

  const removeDate = (index) => {
    if (dates.length > 1) {
      const newDates = dates.filter((_, i) => i !== index);
      setDates(newDates);
    } else {
      // Si c'est la dernière date, on la vide simplement
      setDates([{ date: '', lieu: '' }]);
    }
  };

  const handleSave = () => {
    // Ne sauvegarder que les dates non vides
    const filteredDates = dates.filter(date => date.date && date.lieu);
    localStorage.setItem('voixdeleau-dates', JSON.stringify(filteredDates));
    localStorage.setItem('voixdeleau-contact', JSON.stringify(contact));
    alert('Modifications enregistrées !');
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <form onSubmit={handleLogin} className="bg-gray-900 p-8 rounded-lg w-full max-w-md">
          <div className="flex items-center justify-center mb-8">
            <Lock className="w-8 h-8" />
          </div>
          <h1 className="text-2xl font-light mb-6 text-center">Administration</h1>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-gray-800 p-3 rounded mb-4"
              placeholder="Mot de passe"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-3"
            >
              {showPassword ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-white text-black py-3 rounded hover:bg-gray-200 transition-colors"
          >
            Se connecter
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-light mb-8">Administration - La Voix de l'Eau</h1>
        
        <div className="mb-12">
          <h2 className="text-xl font-light mb-4">Dates à venir</h2>
          {dates.map((date, index) => (
            <div key={index} className="flex space-x-4 mb-4">
              <input
                type="text"
                value={date.date}
                onChange={(e) => handleDateChange(index, 'date', e.target.value)}
                placeholder="Date (ex: 15 Décembre 2024)"
                className="bg-gray-800 p-3 rounded flex-1"
              />
              <input
                type="text"
                value={date.lieu}
                onChange={(e) => handleDateChange(index, 'lieu', e.target.value)}
                placeholder="Lieu"
                className="bg-gray-800 p-3 rounded flex-1"
              />
              <button
                onClick={() => removeDate(index)}
                className="bg-red-900 px-4 rounded hover:bg-red-800"
              >
                ✕
              </button>
            </div>
          ))}
          <button
            onClick={addDate}
            className="bg-gray-800 px-4 py-2 rounded hover:bg-gray-700 mt-4"
          >
            + Ajouter une date
          </button>
        </div>

        <div className="mb-12">
          <h2 className="text-xl font-light mb-4">Informations de contact</h2>
          <div className="space-y-4">
            <input
              type="email"
              value={contact.email}
              onChange={(e) => setContact({...contact, email: e.target.value})}
              placeholder="Email de contact"
              className="bg-gray-800 p-3 rounded w-full"
            />
            <input
              type="url"
              value={contact.facebook}
              onChange={(e) => setContact({...contact, facebook: e.target.value})}
              placeholder="Lien Facebook"
              className="bg-gray-800 p-3 rounded w-full"
            />
          </div>
        </div>

        <button
          onClick={handleSave}
          className="flex items-center space-x-2 bg-white text-black px-6 py-3 rounded hover:bg-gray-200 transition-colors"
        >
          <Save className="w-5 h-5" />
          <span>Enregistrer les modifications</span>
        </button>
      </div>
    </div>
  );
};

export default Admin;