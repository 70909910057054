import React, { useState, useEffect } from 'react';
import { Play, Calendar, Mail, Volume2, VolumeX, Menu, X, Info, Facebook, Instagram, Share2 } from 'lucide-react';

const App = () => {
  const [showVideo, setShowVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [canToggleSound, setCanToggleSound] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [player, setPlayer] = useState(null);
  const [dates, setDates] = useState([]);
  const [contact, setContact] = useState({
    email: 'compagnie.lagoutte.or@gmail.com',
    facebook: 'https://www.facebook.com/eve.lagrange.1'
  });
  const [showShare, setShowShare] = useState(false);

  // Animation initiale
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    // Charger l'API YouTube
    if (showVideo) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        const newPlayer = new window.YT.Player('youtube-video', {
          events: {
            onReady: (event) => {
              setPlayer(event.target);
            },
            onStateChange: (event) => {
              if (event.data === window.YT.PlayerState.ENDED) {
                event.target.playVideo();
              }
            }
          }
        });
      };
    }
  }, [showVideo]);

  useEffect(() => {
    if (showVideo && showTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showVideo, showTooltip]);

  // Chargement des données
  useEffect(() => {
    const savedDates = localStorage.getItem('voixdeleau-dates');
    const savedContact = localStorage.getItem('voixdeleau-contact');
    
    if (savedDates) setDates(JSON.parse(savedDates));
    if (savedContact) setContact(JSON.parse(savedContact));
  }, []);

  const handleMuteToggle = () => {
    if (!canToggleSound || !player) return;
    
    setCanToggleSound(false);
    if (isMuted) {
      player.unMute();
    } else {
      player.mute();
    }
    setIsMuted(!isMuted);
    
    setTimeout(() => setCanToggleSound(true), 1000);
  };

  const shareContent = async () => {
    const shareData = {
      title: 'La Voix de l\'Eau',
      text: 'Découvrez La Voix de l\'Eau, un spectacle en feu, en danse, et en mots.',
      url: window.location.href
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        setShowShare(true);
        setTimeout(() => setShowShare(false), 3000);
      }
    } catch (err) {
      console.log('Error sharing:', err);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {isLoading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black">
          <div className="animate-pulse text-3xl font-light">La Voix de l'Eau</div>
        </div>
      ) : !showVideo ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center space-y-8 bg-black z-50">
          <h1 className="text-4xl md:text-6xl font-light tracking-wider text-center px-4 animate-fade-in">
            LA VOIX DE L'EAU
          </h1>
          <p className="text-gray-400 text-center max-w-md px-4 animate-fade-in-delay">
            Un spectacle en feu, en danse, et en mots
          </p>
          <button 
            onClick={() => setShowVideo(true)}
            className="flex items-center space-x-2 border border-white px-6 py-3 hover:bg-white hover:text-black transition-colors animate-fade-in-delay-2"
          >
            <Play className="w-6 h-6" />
            <span>ENTRER</span>
          </button>
        </div>
      ) : (
        <div className="relative w-full h-screen">
          <iframe 
            id="youtube-video"
            className="w-full h-full object-cover"
            src="https://www.youtube.com/embed/4ACOnBtkX68?autoplay=1&controls=0&mute=1&playsinline=1&loop=1&playlist=4ACOnBtkX68&enablejsapi=1&rel=0&showinfo=0&modestbranding=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="La Marée"
          />

          {/* Bouton d'informations centré en haut */}
          <button 
            onClick={() => setShowMenu(!showMenu)}
            className="fixed top-6 left-1/2 transform -translate-x-1/2 border border-white text-white bg-black bg-opacity-50 px-6 py-3 rounded-full hover:bg-white hover:text-black transition-colors flex items-center space-x-2 z-10"
          >
            <Info className="w-5 h-5" />
            <span>Informations</span>
          </button>

          {/* Contrôles au centre */}
          <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-6 z-10">
            <button 
              onClick={handleMuteToggle}
              className="p-3 bg-black bg-opacity-50 hover:bg-white hover:text-black transition-colors rounded-full group relative"
            >
              {isMuted ? <VolumeX className="w-6 h-6" /> : <Volume2 className="w-6 h-6" />}
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black px-2 py-1 text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap">
                {isMuted ? 'Activer le son' : 'Couper le son'}
              </span>
            </button>

            <button 
              onClick={() => setShowMenu(!showMenu)}
              className="p-3 bg-black bg-opacity-50 hover:bg-white hover:text-black transition-colors rounded-full group relative"
            >
              <Calendar className="w-6 h-6" />
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black px-2 py-1 text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Dates & Infos
              </span>
            </button>
            <button 
              onClick={shareContent}
              className="p-3 bg-black bg-opacity-50 hover:bg-white hover:text-black transition-colors rounded-full group relative"
            >
              <Share2 className="w-6 h-6" />
              <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black px-2 py-1 text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Partager
              </span>
            </button>
          </div>


          {/* Message de partage */}
          {showShare && (
            <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-white text-black px-4 py-2 rounded-full z-50 animate-fade-in">
              URL copiée !
            </div>
          )}

          {/* Tooltip d'aide */}
          {showTooltip && (
            <div className="fixed bottom-32 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-75 px-4 py-2 rounded-full flex items-center space-x-2 z-10 animate-fade-in">
              <Info className="w-4 h-4" />
              <span className="text-sm">Utilisez les contrôles pour le son et les informations</span>
            </div>
          )}

          {/* Menu latéral */}
          <div 
            className={`fixed top-0 right-0 h-full w-80 bg-black bg-opacity-95 transform transition-transform duration-300 ease-in-out ${
              showMenu ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className="h-full overflow-y-auto">
              <div className="p-8 space-y-8">
                <div>
                  <h2 className="text-2xl font-light mb-6 border-b border-gray-800 pb-2">DATES À VENIR</h2>
                  <div className="space-y-6">
                    {dates.length > 0 ? dates.map((date, index) => (
                      <div key={index} className="border-l-2 border-white pl-4">
                        <p className="font-bold text-lg">{date.date}</p>
                        <p className="text-gray-400">{date.lieu}</p>
                      </div>
                    )) : (
                      <p className="text-gray-400 italic">Nouvelles dates bientôt...</p>
                    )}
                  </div>
                </div>

                <div className="border-t border-gray-800 pt-8">
                  <h2 className="text-2xl font-light mb-6">À PROPOS</h2>
                  <p className="text-gray-300 leading-relaxed">
                    Sur le fil d'une danse, une rencontre. La marée monte sous les pieds, 
                    l'eau pénètre dans les failles, le vent se lève. Un spectacle unique 
                    où le feu et la danse se mêlent pour créer une expérience poétique inoubliable.
                  </p>
                  <div className="mt-4">
                    <p className="text-sm text-gray-400 mb-2">Avec :</p>
                    <ul className="list-disc list-inside text-gray-300 space-y-1">
                      <li>Eve Lagrange (Texte, Voix, Danse)</li>
                      <li>Ludwig Dapremont (Acrobatie, Danse)</li>
                    </ul>
                  </div>
                </div>

                <div className="border-t border-gray-800 pt-8">
                  <h2 className="text-2xl font-light mb-6">CONTACT</h2>
                  <div className="space-y-4">
                    <a 
                      href={`mailto:${contact.email}`}
                      className="flex items-center space-x-3 hover:text-gray-300 group"
                    >
                      <Mail className="w-6 h-6" />
                      <span className="group-hover:underline">{contact.email}</span>
                    </a>
                    <a 
                      href={contact.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center space-x-3 hover:text-gray-300 group"
                    >
                      <Facebook className="w-6 h-6" />
                      <span className="group-hover:underline">Facebook</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;